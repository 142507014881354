<template>
  <div class="flex flex-col lg:flex-row gap-5 lg:gap-9 rounded-5 lg:rounded-3.5 lg:ring-1.5 ring-inset ring-firmennyy/20 p-5 lg:p-6 lg:w-fit max-lg:bg-white">
    
<FormKitLazyProvider config-file="true" :default-config="false">
<FormKit
      type="form"
      form-class="flex flex-col gap-6 lg:gap-5"
      :incomplete-message="false"
      :config="{ outerClass: '', innerClass: '$remove:border-1 bg-plashka-green lg:bg-white', inputClass: '$remove:text-tekstovyy text-firmennyy placeholder:text-firmennyy/50 $remove:placeholder:text-#AAB8C1' }"
      :actions="false"
      @submit="addReview"
    >
      <p class="text-tekstovyy text-6.5 font-700 -mb-2">Ваш отзыв</p>
      <FormKit
        validation="required"
        name="score"
        type="rating"
        inner-class=""
        :classes="{ iconWrapper: 'max-lg:bg-rating-plashka-green' }"
      />
      <FormKit
        validation="required"
        name="message"
        type="textarea"
        :classes="{ input: 'h-14 lg:h-20' }"
        placeholder="Ваш отзыв"
      />
      <Button
        type="submit"
        :ui="{ rounded: 'rounded-2.5 lg:rounded-3.5' }"
      >
        Оставить отзыв
      </Button>
    </FormKit>
    <div class="flex lg:flex-col max-lg:gap-3.5 max-lg:order-first">
      <img
        class="size-22.5 lg:size-35 mix-blend-darken"
        src="/imgs/write-a-review.png"
        alt=""
      >
      <p class="mt-4 text-firmennyy text-3 font-500 leading-1.3 lg:text-center max-w-35">
        Напишите нам и мы постараемся сделать вкус блюд и сервис
        намного лучше
      </p>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { AddReviewsReqType } from '~/composables/reviews/types/ReviewsType';

const emits = defineEmits<{
	addReview: [form: Omit<AddReviewsReqType, 'name'>]
}>();

const addReview = async (form: Omit<AddReviewsReqType, 'name'>) => {
  await nextTick();
  emits('addReview', { ...form });
};
</script>
