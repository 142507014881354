<template>
  <div class="flex flex-col gap-5 lg:gap-7">
    <slot
      v-if="canCreateFeedback || reviewsList.length"
      name="title"
      :title="title"
    >
      <p class="flex gap-1.5 lg:gap-2.5 items-center text-6 lg:text-8.5 text-tekstovyy font-700">
        <span class="i-custom:message text-firmennyy"></span>
        {{ title }}
      </p>
    </slot>

    <ReviewsAdd
      v-if="canCreateFeedback"
      :class="reviewFormWrapperClass"
      @add-review="addReview"
    />

    <div
      v-if="reviewsList.length"
      class="flex flex-col gap-4 lg:gap-2.5"
    >
      <div
        v-for="(review, idx) in reviewsList"
        :key="`review-${idx}`"
        class="grid grid-cols-1 lg:grid-cols-[4fr_3fr] gap-y-1.5 gap-x-4 items-start first:before:content-none max-lg:before:content-empty max-lg:before:w-12 max-lg:before:h-0.75 max-lg:before:mx-auto max-lg:before:bg-firmennyy/40 max-lg:before:rounded-full max-lg:before:mb-2.5 max-w-190"
      >
        <ReviewsCard
          :review="review"
        />
        <!-- <ReviewsAnswer /> -->
      </div>
      <!--      <Button-->
      <!--        class="mt-4"-->
      <!--        variant="outline"-->
      <!--      >-->
      <!--        Показать ещё отзывы-->
      <!--      </Button>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { useReviews } from '~/composables/reviews/useReviews';
import type { AddReviewsReqType, ProductReviewByIdType, RestaurantReviewByIdType } from '~/composables/reviews/types/ReviewsType';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { Reviews } from '~/utils/reviews/Reviews';

const props = defineProps<{
	title?: string;
	productId?: number;
	restaurantId?: number;
	reviewFormWrapperClass?: string;
	canCreateFeedback:boolean;
}>();

const { getProductReviewsById, addProductReviews, getRestaurantReviewsById, addRestaurantReviews } = useReviews();
const { currentUser } = useUserAuth();
const reviewsList = ref<ProductReviewByIdType[] | RestaurantReviewByIdType[]>([]);

const reviewProduct = Reviews.create<{ product_id: number }>({ product_id: props.productId || 0 }, getProductReviewsById, addProductReviews);

const reviewRestaurant = Reviews.create<{ restaurant_id: number }>({ restaurant_id: props.restaurantId || 0 }, getRestaurantReviewsById, addRestaurantReviews);

async function fetchGetReviews (reviewService: () => Promise<any>) {
  if (reviewService) {
    const res = await reviewService();

    if (res) {
      reviewsList.value = res;
    }
  }
}

const { $toast } = useNuxtApp();

const addReview = async (form: Omit<AddReviewsReqType, 'name'>) => {
  if (props.productId && reviewProduct) {
    const res = await reviewProduct?.addReview({ message: form.message, name: currentUser.value?.login || currentUser.value?.name || currentUser.value?.phone || '', score: form.score }).catch((err) => {
	    $toast.error(err.data.message);
    });

    if (res) {
      $toast.success('отзыв успешно добавлен');
	    await fetchGetReviews(() => reviewProduct.getReviews());
    }
  }

  if (props.restaurantId && reviewRestaurant) {
    const res = await reviewRestaurant?.addReview({ message: form.message, name: currentUser.value?.login || '', score: form.score }).catch((err) => {
	    $toast.error(err.data.message);
    });

    if (res) {
      $toast.success('отзыв успешно добавлен');
	    await fetchGetReviews(() => reviewRestaurant.getReviews());
    }
  }
};

onMounted(async () => {
  if (props.productId && reviewProduct) {
    await fetchGetReviews(() => reviewProduct.getReviews());
  }

  if (props.restaurantId && reviewRestaurant) {
    await fetchGetReviews(() => reviewRestaurant.getReviews());
  }
});
</script>
